import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Popover} from 'react-tiny-popover'

import AbandonedFiles from '../../svg/AbandonedFiles'
import {EFolderStatus} from '../../../../domain/Folder/EFolderStatus'
import {ERole, roleBackOfficeList} from '../../../../domain/User/Erole'
import {PropsActions} from './types'
import storage from '../../../util/storage'
import { isFolderDuplicationEnabled } from '../../../util/envVariable'
import SignHistory from '../../svg/SignHistory'
import DownloadDoc from '../../svg/DownloadDoc'
import DuplicateFolder from '../../svg/DuplicateFolder'
import Edit from '../../svg/Edit'

const FolderActions: React.FC<PropsActions> = ({
  FolderDetail,
  role,
  handleEdit,
  handleDownload,
  handleDocuSignHistory,
  handleManageDossier,
  setIsPopoverOpen,
  isPopoverOpen,
  toggle,
  toggleDuplicate,
  allowDownloadDocument,
  setError,
  type
}) => {
  const {t} = useTranslation()
  const statusHideAllButton: string[] = [EFolderStatus.Canceled, EFolderStatus.Rejected]
  const roleHideButtonDeleted: string[] = [ERole.Client, ERole.Prospect, ERole.Back_office_user, ERole.ManageDossier]
  const roleShowButtonDeletedBO: string[] = [ERole.ManageDossier]
  const roleHideButtonModify: string[] = [ERole.Back_office_user, ERole.ManageDossier]
  const roleHideButtonHistorySignature: string[] = [ERole.Client, ERole.Prospect]
  const roleHideManageDossier: string[] = [ERole.Client, ERole.Prospect, ERole.Back_office_user]
  const statusHideButtonDeleted: string[] = [EFolderStatus.Signed]
  const roleHideButtonDuplicate: string[] = [ERole.Client, ERole.Prospect, ERole.DirectProspect, ERole.Back_office_user, ERole.ManageDossier]
  const statusHideButtonDuplicate: string[] = [EFolderStatus.Signed]

  const [isPopoverOpenDownload, setIsPopoverOpenDownload] = useState<boolean>(false)
  const [isPopoverOpenHistory, setIsPopoverOpenHistory] = useState<boolean>(false)
  const [isPopoverOpenDuplicate, setIsPopoverOpenDuplicate] = useState<boolean>(false)
  const [isPopoverOpenModify, setIsPopoverOpenModify] = useState<boolean>(false)
  const [isPopoverOpenManage, setIsPopoverOpenManage] = useState<boolean>(false)

  return (
    <div className="flex">
      {!statusHideAllButton.includes(FolderDetail.status) && (
        <>
          {allowDownloadDocument && (type !== 'manage' &&
            (FolderDetail.status === 'signature' || FolderDetail.status === 'signed')) && (
            <Popover
              isOpen={isPopoverOpenDownload}
              positions={['bottom']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{t('button.download-document')}</div>}>
              <button data-id={FolderDetail.id} className="button-reset button--medium button--width-fit button-icon-action"
                      onMouseEnter={() => setIsPopoverOpenDownload(true)}
                      onMouseLeave={() => setIsPopoverOpenDownload(false)}
                      onClick={handleDownload}>
                <DownloadDoc/>
              </button>
            </Popover>
          )}

          {(!roleHideButtonHistorySignature.includes(role) && type !== 'manage' &&
            (FolderDetail.status === EFolderStatus.Signature || FolderDetail.status === EFolderStatus.Signed)) && (
            <Popover
              isOpen={isPopoverOpenHistory}
              positions={['bottom']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{t('docusign.history')}</div>}>
              <button data-id={FolderDetail.id} className="button-reset button--medium button--width-fit button-icon-action"
                      onMouseEnter={() => setIsPopoverOpenHistory(true)}
                      onMouseLeave={() => setIsPopoverOpenHistory(false)}
                      onClick={handleDocuSignHistory}>
                <SignHistory/>
              </button>
            </Popover>
          )}
          {(!roleHideButtonDuplicate.includes(role) && !statusHideButtonDuplicate.includes(FolderDetail.status)) && type !== 'manage' &&
            isFolderDuplicationEnabled() && (
            <Popover
              isOpen={isPopoverOpenDuplicate}
              positions={['bottom']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{t('button.duplicate')}</div>}>
              <button data-id={FolderDetail.id} className="button-reset button--medium button--width-fit button-icon-action"
                      onMouseEnter={() => setIsPopoverOpenDuplicate(true)}
                      onMouseLeave={() => setIsPopoverOpenDuplicate(false)}
                      onClick={toggleDuplicate}>
                <DuplicateFolder/>
              </button>
            </Popover>
          )}

          {(!roleHideButtonModify.includes(role) && type !== 'manage' &&
            (FolderDetail.status !== EFolderStatus.Signature && FolderDetail.status !== EFolderStatus.Signed)) && (
            <Popover
              isOpen={isPopoverOpenModify}
              positions={['bottom']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{t('button.modify')}</div>}>
              <button data-id={FolderDetail.id} className="button-reset button--medium button--width-fit button-icon-action"
                onMouseEnter={() => setIsPopoverOpenModify(true)}
                onMouseLeave={() => setIsPopoverOpenModify(false)}
                onClick={handleEdit}>
                <Edit />
              </button>
            </Popover>
          )}
          {roleHideButtonModify.includes(role) && type !== 'manage' && (
            <Popover
              isOpen={isPopoverOpenModify}
              positions={['bottom']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{FolderDetail.formId === storage.getBackOfficeFormId() && roleBackOfficeList.includes(role) ? t('button.modify') : t('button.view')}</div>}>
              <button data-id={FolderDetail.id} className="button-reset button--medium button--width-fit button-icon-action"
                onMouseEnter={() => setIsPopoverOpenModify(true)}
                onMouseLeave={() => setIsPopoverOpenModify(false)}
                onClick={handleEdit}>
                <Edit />
              </button>
            </Popover>
          )}
          {(!roleHideButtonDeleted.includes(role) && !statusHideButtonDeleted.includes(FolderDetail.status)) && type !== 'manage' && (
            <Popover
              isOpen={isPopoverOpen}
              positions={['right']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{t('folder.abandon-folder')}</div>}>
              <button data-id={FolderDetail.id} className="button-reset button--width-fit button--files-state button--abandon"
                      onMouseEnter={() => setIsPopoverOpen(true)}
                      onMouseLeave={() => setIsPopoverOpen(false)}
                      onClick={() => {
                        setError(false)
                        toggle()
                      }}>
                <AbandonedFiles color="var(--ink-6)"/>
              </button>
            </Popover>
          )}
          {(roleShowButtonDeletedBO.includes(role) && FolderDetail.formId === storage.getBackOfficeFormId() &&
            !statusHideButtonDeleted.includes(FolderDetail.status)) && type !== 'manage' && (
            <Popover
              isOpen={isPopoverOpen}
              positions={['right']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{t('folder.abandon-folder')}</div>}>
              <button data-id={FolderDetail.id} className="button-reset button--width-fit button--files-state button--abandon"
                      onMouseEnter={() => setIsPopoverOpen(true)}
                      onMouseLeave={() => setIsPopoverOpen(false)}
                      onClick={() => {
                        setError(false)
                        toggle()
                      }}>
                <AbandonedFiles color="var(--ink-6)"/>
              </button>
            </Popover>
          )}

          {!roleHideManageDossier.includes(role) && type === 'manage' &&
            <Popover
              isOpen={isPopoverOpenManage}
              positions={['bottom']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{t('button.manage-dossier')}</div>}>
              <button data-id={FolderDetail.id} className="button-reset button--medium button--width-fit button-icon-action"
                onMouseEnter={() => setIsPopoverOpenManage(true)}
                onMouseLeave={() => setIsPopoverOpenManage(false)}
                onClick={handleManageDossier}>
                <Edit />
              </button>
            </Popover>
          }
        </>
      )}
      {FolderDetail.status === EFolderStatus.Canceled && type !== 'manage' && isFolderDuplicationEnabled() && (
        <Popover
          isOpen={isPopoverOpenDuplicate}
          positions={['bottom']}
          align={'center'}
          padding={10}
          content={<div className="box-helpers">{t('button.duplicate')}</div>}>
          <button data-id={FolderDetail.id} className="button-reset button--medium button--width-fit button-icon-action"
            onMouseEnter={() => setIsPopoverOpenDuplicate(true)}
            onMouseLeave={() => setIsPopoverOpenDuplicate(false)}
            onClick={toggleDuplicate}>
            <DuplicateFolder />
          </button>
        </Popover>
      )}
    </div>
  )
}

export default FolderActions
